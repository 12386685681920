<div [ngClass]="{ 'dark text-white-dark': store.semidark }">
    <nav
        class="sidebar fixed bottom-0 top-0 z-50 h-full min-h-screen w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] transition-all duration-300">
        <div class="h-full bg-white dark:bg-[#0e1726]">
            <div class="flex items-center justify-between px-4 py-3">
                <a routerLink="/" class="main-logo flex shrink-0 items-center">
                    <img class="ml-[5px] w-8 flex-none" src="../../assets/images/logo.svg" alt="" />
                    <span
                        class="align-middle text-2xl font-semibold ltr:ml-1.5 rtl:mr-1.5 dark:text-white-light lg:inline">MayoreoGo</span>
                </a>
                <a href="javascript:;"
                    class="collapse-icon flex h-8 w-8 items-center rounded-full transition duration-300 hover:bg-gray-500/10 hover:text-primary rtl:rotate-180 dark:text-white-light dark:hover:bg-dark-light/10"
                    (click)="storeData.dispatch({ type: 'toggleSidebar'})">
                    <icon-carets-down class="m-auto rotate-90" />
                </a>
            </div>
            <ng-scrollbar class="relative !h-[calc(100vh-80px)]" appearance="compact">
                <ul class="relative space-y-0.5 p-4 py-0 font-semibold">


                    <h2
                        class="-mx-4 mb-1 flex items-center bg-white-light/30 px-7 py-3 font-extrabold uppercase dark:bg-dark dark:bg-opacity-[0.08]">
                        <icon-minus class="hidden h-5 w-4 flex-none" />
                        <span>Administración</span>
                    </h2>

                    <li class="nav-item">
                        <ul>
                            <li class="nav-item">
                                <a routerLink="/dashboard/users/admin" class="group" routerLinkActive="active"
                                    (click)="toggleMobileMenu()">
                                    <div class="flex items-center">
                                        <icon-user [fill]="true" class="shrink-0 group-hover:!text-primary" />

                                        <span
                                            class="text-black ltr:pl-3 rtl:pr-3 dark:text-white dark:group-hover:text-white-dark">Administradores</span>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/dashboard/users/usuarios" class="group" routerLinkActive="active"
                                    (click)="toggleMobileMenu()">
                                    <div class="flex items-center">
                                        <icon-users class="shrink-0 group-hover:!text-primary" />

                                        <span
                                            class="text-black ltr:pl-3 rtl:pr-3 dark:text-white dark:group-hover:text-white-dark">Usuarios</span>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/dashboard/planes" class="group" routerLinkActive="active"
                                    (click)="toggleMobileMenu()">
                                    <div class="flex items-center">
                                        <icon-award class="shrink-0 group-hover:!text-primary" />

                                        <span
                                            class="text-black ltr:pl-3 rtl:pr-3 dark:text-white dark:group-hover:text-white-dark">Planes</span>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/dashboard/avance" class="group" routerLinkActive="active"
                                    (click)="toggleMobileMenu()">
                                    <div class="flex items-center">
                                        <icon-award class="shrink-0 group-hover:!text-primary" />

                                        <span
                                            class="text-black ltr:pl-3 rtl:pr-3 dark:text-white dark:group-hover:text-white-dark">Avance
                                            Usuarios</span>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="https://mayoreogov2.com/tienda/auth/checktoken/{{token}}" class="group"
                                    target="_blank" (click)="toggleMobileMenu()">
                                    <div class="flex items-center">
                                        <icon-users class="shrink-0 group-hover:!text-primary" />

                                        <span
                                            class="text-black ltr:pl-3 rtl:pr-3 dark:text-white dark:group-hover:text-white-dark">Tienda</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <h2
                        class="-mx-4 mb-1 flex items-center bg-white-light/30 px-7 py-3 font-extrabold uppercase dark:bg-dark dark:bg-opacity-[0.08]">
                        <icon-minus class="hidden h-5 w-4 flex-none" />
                        <span>Catálogos</span>
                    </h2>

                    <li class="nav-item">
                        <a routerLink="/dashboard/mayoristas" class="group" routerLinkActive="active"
                            (click)="toggleMobileMenu()">
                            <div class="flex items-center">
                                <icon-menu-charts class="shrink-0 group-hover:!text-primary" />

                                <span
                                    class="text-black ltr:pl-3 rtl:pr-3 dark:text-white dark:group-hover:text-white-dark">Mayoristas</span>
                            </div>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a routerLink="/dashboard/cedis/" class="group" routerLinkActive="active"
                            (click)="toggleMobileMenu()">
                            <div class="flex items-center">
                                <icon-menu-contacts class="shrink-0 group-hover:!text-primary" />

                                <span
                                    class="text-black ltr:pl-3 rtl:pr-3 dark:text-white dark:group-hover:text-white-dark">Sucursales/Cedis</span>
                            </div>
                        </a>
                    </li>


                    <li class="nav-item">
                        <a routerLink="/dashboard/zonas" class="group" routerLinkActive="active"
                            (click)="toggleMobileMenu()">
                            <div class="flex items-center">
                                <icon-send class="shrink-0 group-hover:!text-primary" />

                                <span
                                    class="text-black ltr:pl-3 rtl:pr-3 dark:text-white dark:group-hover:text-white-dark">Zonas</span>
                            </div>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a routerLink="/dashboard/rutas" class="group" routerLinkActive="active"
                            (click)="toggleMobileMenu()">
                            <div class="flex items-center">
                                <icon-share class="shrink-0 group-hover:!text-primary" />

                                <span
                                    class="text-black ltr:pl-3 rtl:pr-3 dark:text-white dark:group-hover:text-white-dark">Rutas</span>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/dashboard/perfiles" class="group" routerLinkActive="active"
                            (click)="toggleMobileMenu()">
                            <div class="flex items-center">
                                <icon-userid class="shrink-0 group-hover:!text-primary" />

                                <span
                                    class="text-black ltr:pl-3 rtl:pr-3 dark:text-white dark:group-hover:text-white-dark">Perfiles</span>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/dashboard/metricas" class="group" routerLinkActive="active"
                            (click)="toggleMobileMenu()">
                            <div class="flex items-center">
                                <icon-bar-chart class="shrink-0 group-hover:!text-primary" />

                                <span
                                    class="text-black ltr:pl-3 rtl:pr-3 dark:text-white dark:group-hover:text-white-dark">Metricas</span>
                            </div>
                        </a>
                    </li>

                    <li class="accordion menu nav-item">
                        <button type="button" class="nav-link group w-full"
                            [ngClass]="{ active: activeDropdown.includes('clientes') }"
                            (click)="toggleAccordion('clientes')">
                            <div class="flex items-center">
                                <icon-menu-users class="shrink-0 group-hover:!text-primary" />

                                <span
                                    class="text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Clientes</span>
                            </div>
                            <div [ngClass]="{ 'rtl:rotate-90 -rotate-90': !activeDropdown.includes('clientes') }">
                                <icon-caret-down />
                            </div>
                        </button>
                        <div [@slideDownUp]="!activeDropdown.includes('clientes')" class="accordion-content">
                            <ul class="sub-menu text-gray-500">
                                <li>
                                    <a routerLink="/dashboard/clientes/marcas" routerLinkActive="active"
                                        (click)="toggleMobileMenu()">Marcas</a>
                                </li>
                                <li>
                                    <a routerLink="/dashboard/clientes/submarcas" routerLinkActive="active"
                                        (click)="toggleMobileMenu()">SubMarcas</a>
                                </li>
                                <li>
                                    <a routerLink="/dashboard/clientes/usuarios" routerLinkActive="active"
                                        (click)="toggleMobileMenu()">Usuarios</a>
                                </li>
                            </ul>
                        </div>
                    </li>



                </ul>
            </ng-scrollbar>
        </div>
    </nav>
</div>